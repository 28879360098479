
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import Throttler from "@/utils/Throttler";

@Component
export default class VThrottlerInput extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ default: 500 }) readonly delay!: number;
  @Prop({ default: "Search..." }) readonly placeholder!: string;

  private inputValue = "";

  private throttler: Throttler = new Throttler(this.delay);

  created() {
    this.inputValue = this.value;
  }

  @Watch("inputValue")
  emitValueWithDelay() {
    if (this.inputValue === this.value) {
      return;
    }

    this.throttler.run(() => {
      this.$emit("input", this.inputValue);
    });
  }
}
